<template>
  <!-- 登录背景 -->
  <div
    class="login-back"
    :style="{
      background:
        'url(' + require('@/assets/images/testlogin_back.gif') + ')' + 'no-repeat',
    }"
  >
    <!-- 登录logo -->
    <!-- <img class="logo" src="@/assets/images/login-logo.png" alt="" /> -->
    <img class="logo_zhineng" src="@/assets/images/zhineng_logo.png" alt="" />
    <div class="box_outer">
      <div class="title">智能化多媒体管理和生产系统</div>
      <!-- 找回密码容器 -->
      <div class="forget">
        <!-- 找回密码标题 -->
        <div class="forget-title" style="font-size:16px">找回密码</div>
        <!-- 忘记密码表单 -->
        <el-form
          :model="Form"
          ref="dynamicValidateForm"
          class="demo-dynamic"
          :rules="rules"
        >
          <!-- 邮箱 -->
          <el-form-item prop="email" :error="error">
            <el-input
              @keyup.enter.native="sendValidate('dynamicValidateForm')"
              onkeypress="javascript:if(event.keyCode == 32 || event.keyCode == 13)event.returnValue = false;"
              v-model="Form.email"
              placeholder="请输入你的邮箱"
            ></el-input>
          </el-form-item>
          <!-- 发送验证邮件按钮-->
          <el-form-item>
            <el-button
              v-if="error == ''"
              type="primary"
              @click="sendValidate('dynamicValidateForm')"
              >发送验证邮件</el-button
            >
            <el-button type="primary" v-else @click="register"
              >发送激活邮件</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单验证规则
      rules: {
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请填写正确邮箱",
            trigger: "blur",
          },
        ],
      },
      // 表单绑定值
      Form: {
        email: "",
      },
      // 表单错误提示信息
      error: "",
    };
  },

  methods: {
    // 发送验证邮件按钮
    sendValidate(formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证发起邮件
        if (valid) {
          // 发送验证邮件请求
          let formData = new FormData();
          formData.append("email", this.Form.email);
          this.$http({
            method: "POST",
            url: "accounts/retrieve_password_email",
            data: formData,
          }).then((res) => {
            // console.log(res.data);
            // 发送成功
            if (res.data.status == 0) {
              // 将邮箱做一下缓存，方便ValidateEmail页面进行使用
              sessionStorage.setItem('Email',this.Form.email)
              // 跳转重置密码页
              this.$router.push({
                path: "/ResetTip"
              });
            }
            //  发送失败
            else if (res.data.status == 1) {
              this.error = "";
              this.$nextTick(() => {
                this.error = res.data.msg;
              });
            }
          }).catch(error => console.log(error));
        } else {
          // 没通过验证返回错误，不进行下一步
          return false;
        }
      });
    },
    // 发送验证邮件
    register() {
      let formData = new FormData()
      formData.append("email", this.Form.email)
      this.$http({
        method: "POST",
        url: "accounts/register_user",
        data: formData,
      }).then((res) => {
        // 注册成功跳转验证邮箱界面
        if (res.data.status == 0) {
          this.$router.push({
            path: "/validatemail",
            query: {
              email: this.Form.email,
            },
          });
        }
      }).catch(error => console.log(error));
    },
  },
};
</script>

<style lang="scss" scoped>
//找回密码背景
.login-back {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size: cover !important;
  // 登录背景logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
    margin-right: 20.5vw;
  }
  .logo_zhineng{
    width: 13vw;
    margin: 12vh 19.5vw 1vh auto;
  }
  .box_outer{
   margin-right: 12.45vw;
  }
  // 标题
  .title {
    // margin-right: 16vw;
    font-size: 1.6vw;
    // font-family: Microsoft YaHei;
    // font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    text-align: center;
    // opacity: 1;
  }
  // 找回密码容器
  .forget {
    margin-top: 6.9vh;
    width: 27.7vw;
    height: 47.6vh;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    .forget-title {
      margin-left: 6.56vw;
      padding-top: 11.84vh;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
    }
    .demo-dynamic {
      width: 14.6vw;
      height: 4.3vh;
      margin-top: 4.7vh;
      margin-left: 6.56vw;
    }
    /deep/.el-input__inner {
      background: #393846;
      border-radius: 5px;
      opacity: 0.6;
      color: #fff;
      font-size: 14px;
    }
    /deep/.el-form-item__error {
        color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 30%;
      left: auto;
      right: -6vw;
      width: 5.9vw;
    }
    /deep/.el-button--primary {
       width: 14.6vw;
    height: 4.3vh;
    }
    /deep/.el-form-item__content {
      display: flex;
    }
  }
}
</style>
